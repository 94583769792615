<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Bank malumotlari</h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row flex-wrap" v-if="list.payment_source == 'Bank'">
                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Manba"
                    :value="list.payment_source"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Transfer nomi"
                    :value="list.transfer_name"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Transfer nomi"
                    :value="list.transfer_name"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Summa"
                    :value="list.amount"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Izoh"
                    :value="list.comment"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Izoh"
                    :value="list.comment"
                  ></v-text-field>
                </div>
              </div>

              <div class="row flex-wrap">
                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Karta raqami"
                    :value="list.card_number"
                  ></v-text-field>
                </div>
                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Operatsiya nomi"
                    :value="list.operation_perpose"
                  ></v-text-field>
                </div>
                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Operatsiya turi"
                    :value="list.operation_type"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Sana"
                    :value="list.oper_date | dateFilter"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Summa"
                    :value="list.amount"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Izoh"
                    :value="list.comment"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="7"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {}
  },
  created() {
    this.$store.dispatch('getTransferDetails', this.$route.params.id)
  },
  computed: {
    list() {
      return this.$store.state.requests.transferDetails
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Vipiska' },
      { title: 'Bank malumotlari' }
    ])
  }
}
</script>
